.social-networks-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 40px;

	@include media('<tablet') {
		display: block;
	}

	.social-networks {
		margin: -5px;

		@include media('<tablet') {
			padding-top: 14px;
		}
	}
}

.social-networks {
	margin: -5px -5px 20px;
	padding: 0;
	font-size: 0;
	list-style: 0;

	li {
		display: inline-block;
		vertical-align: top;
		padding: 5px;

		&:before {
			display: none;
		}
	}

	a {
		@include size(46px);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		line-height: 1;
		color: $alt-text-color;
		text-decoration: none;
		border: 1px solid currentColor;
		border-radius: 50%;

		&:hover {
			color: $primary;
		}
	}
}