.product-nav {
    padding-block: 19px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    background: #2a2e35;

    @include media('<widescreen') {
        display: none;
    }

    .container {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        gap: 8px;
    }

    li {
        padding: 0;

        &:before {
            display: none;
        }

        &.active {
            a {
                background: rgba($white, 0.2);
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 9px 4px;
        color: rgba($white, 0.8);
        text-decoration: none;
        border-radius: 8px;

        &:hover {
            background: rgba($white, 0.1);
        }
    }
}