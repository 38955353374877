// forms

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
// input[type='date'],
textarea {
  -webkit-appearance: none;
  padding: $form-element-padding;
  font-size: 16px;
  line-height: 20px;
  color: $form-element-text-color;
  outline:0;
  border: 1px solid $form-element-border-color;
  border-radius: 3px;
  background: $form-element-background-color;
  transition: background-color $animation-speed $animation-effect;

  &:hover {
    border-color: darken($form-element-border-color, 9%);
  }

  &:focus {
    border-color: $form-element-focus-border-color;
    background: $white;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

select {
  -webkit-border-radius: 0;
}

textarea {
  resize: vertical;
  vertical-align: top;
  max-width: 100%;
  overflow: auto;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

.label,
label{
  display: inline-block;
  vertical-align: top;
}

.input-holder{
  @extend %clearfix;
  position: relative;
}

.input-placeholder-text{
  white-space: nowrap;
  cursor: text;

  .area &{
    white-space: normal;
  }
}

.search-form {
  margin: 0 0 24px;
}

.subscribe-form-block {
  margin: 0 0 24px;
  padding: 30px 40px 50px;
  color: $white;
  border-radius: 20px;
  background-color: #263040;

  @include media('<tablet') {
    margin: 0 -16px 24px;
    padding: 20px 16px 30px;
    border-radius: 0;
  }

  h4 {
    margin: 0 0 23px;
  }

  .subscribe-form {
    position: relative;
    border-radius: 20px;
    border: 1px solid #fff;

    input {
      width: 100%;
      padding: 20px 188px 20px 22px;
      font-size: 18px;
      font-weight: bold;
      color: $white;
      border: 0;
      border-radius: 19px;
      background: none;

      &:hover {
        background: rgba($black, 0.08);
      }

      &:focus {
        background: rgba($black, 0.15);
      }
    }

    .btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 163px;
      margin: 5px;
      border-radius: 15px;
    }
  }
}