/* Header styles */
.nav-opened {
	@include media('<desktop') {
		overflow: hidden;
	}
}

.header {
	position: fixed;
	z-index: 2;
	left: 0;
	top: 0;
	width: 100%;
	color: $alt-text-color;

	@include media('>=desktop') {
		padding: 24px 20px 0;
	}

	&.scrolled {
		.container {
			border-color: $alt-border-color;
			box-shadow: 0 4px 20px rgba($black, 0.1);
			background: $white;
		}
	}

	.alt-header &,
	&.dark-mode {
		color: $white;

		&.scrolled {
			.container {
				border-color: #31363F;
				box-shadow: 0 4px 20px rgba($black, 0.15);
				background: $dark-background-color;
			}
		}

		.nav {
			@include media('<desktop') {
				background: $dark-background-color;
			}

			> ul > li {
				@include media('<desktop') {
					border-color: $alt-border-color-inverted;
				}
			}
		}

		.nav-drop-menu {
			border-color: #31363F;
			box-shadow: 0 4px 20px rgba($black, 0.15);
			background: $dark-background-color;

			.active a,
			a:hover {
				background: rgba($white, 0.05);
			}

			span {
				color: $placeholder-color-alt;
			}
		}
	}

	.alt-header & {
		background: $dark-background-color;

		@include media('>=desktop') {
			padding: 16px 0;
		}

		.container {
			padding: 0 16px;
			border: 0;
			box-shadow: none !important;
			background: none !important;

			@include media('<desktop') {
				padding-right: 0;
			}
		}
	}

	&:hover {
		.nav > ul > li > a,
		.link-sign {
			@include media('>=desktop') {
				opacity: 0.6;
			}
		}

		.nav > ul > li {
			&:hover {
				> a {
					@include media('>=desktop') {
						opacity: 1;
					}

					&:after {
						transform: scale(1, 1);
					}
				}
			}
		}

		.link-sign:hover {
			opacity: 1;

			&:after {
				transform: scale(1, 1);
			}
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		max-width: 1240px;
		padding: 0 0 0 16px;
		border: 1px solid transparent;
		background: transparent;
		transition: background-color $animation-speed $animation-effect, border-color $animation-speed $animation-effect;

		@include media('>=desktop') {
			padding: 19px;
			border-radius: 10px;
		}

		@include media('<desktop') {
			border-width: 0 0 1px;
		}
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	.nav > ul > li > a,
	.link-sign {
		@include media('>=desktop') {
			position: relative;
			padding: 6px 0 4px;
			line-height: 32px;

			&:after {
				@include size(100%, 2px);
				position: absolute;
				content: '';
				left: 0;
				bottom: 0;
				background: $primary;
				transform: scale(0, 1);
				transition: transform $animation-speed $animation-effect;
			}
		}
	}
}

.logo {
	flex-shrink: 0;
	display: block;
	margin: 0 46px 0 0;

	@include media('<widescreen') {
		margin: 0 30px 0 0;
	}

	a {
		display: flex;
		align-items: center;
	}

	img {
		@include media('<desktop') {
			@include size(28px);
		}
	}

	.logo-text {
		margin-left: 15px;

		@include media('<desktop') {
			@include size(100px, 19px);
			margin-left: 13px;
		}
	}
}

.navbar-toggler {
	@include size(60px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	@include media('>=desktop') {
		display: none;
	}

	span {
		@include size(24px, 8px);
		position: relative;

		&:before,
		&:after {
			@include size(100%, 2px);
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			background: currentColor;
			transition:
				transform $animation-speed $animation-effect,
				top $animation-speed $animation-effect $animation-speed,
				bottom $animation-speed $animation-effect $animation-speed;
		}

		&:after {
			top: auto;
			bottom: 0;
		}
	}

	.nav-opened & {
		span {
			&:before,
			&:after {
				transition:
					transform $animation-speed $animation-effect $animation-speed,
					top $animation-speed $animation-effect,
					bottom $animation-speed $animation-effect;
			}

			&:before {
				top: 3px;
				transform: rotate(45deg);
			}

			&:after {
				bottom: 3px;
				transform: rotate(-45deg);
			}
		}
	}
}

.nav {
	flex-grow: 1;
	display: flex;
	align-items: center;

	@include media('<desktop') {
		display: flex;
		flex-direction: column;
		position: fixed;
		left: 0;
		top: 60px;
		bottom: 0;
		width: 100%;
		line-height: 32px;
		background: $white;
		opacity: 0;
		pointer-events: none;
		transition: opacity $animation-speed $animation-effect;
	}

	.nav-opened & {
		@include media('<desktop') {
			opacity: 1;
			pointer-events: auto;
		}
	}

	> ul {
		flex-grow: 1;
		display: flex;
		padding: 0 45px 0 0;

		@include media('<desktop') {
			display: block;
			width: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 0 16px;
		}

		> li {
			position: static;
			margin: 0 20px -22px;
			padding: 0 0 22px;

			@include media('<widescreen') {
				margin: 0 14px -22px;
			}

			@include media('<desktop') {
				margin: 0;
				padding: 0;
				border-top: 1px solid $alt-border-color;
			}

			&:first-child {
				@include media('<desktop') {
					border: 0;
				}
			}

			&:before {
				display: none;
			}

			&.active,
			&:hover {
				> a:after {
					transform: scale(1, 1);
				}
			}

			&:hover {
				> a:before {
					@include media('>=desktop') {
						transform: rotate(180deg);
					}
				}

				.nav-drop {
					@include media('>=desktop') {
						opacity: 1;
						pointer-events: auto;
						transform: translateY(0);
					}
				}
			}

			&.dropdown {
				&.opened > a:before {
					@include media('<desktop') {
						transform: rotate(180deg)
					}
				}

				> a {
					position: relative;
					padding-right: 20px;

					&:before {
						@include add-icon("\e905"); // icon-chevron-down
						position: absolute;
						right: -3px;
						top: 13px;
						font-size: 18px;
						transform-origin: 50% 58%;
						transition: transform $animation-speed $animation-effect;

						@include media('<desktop') {
							right: 0;
							top: 17px;
						}
					}
				}
			}

			> a {
				@include media('<desktop') {
					padding: 14px 0 13px;
				}
			}
		}
	}

	a {
		display: block;

		&.disabled-link {
			cursor: default;
		}
	}
}

.nav-drop {
	@include media('>=desktop') {
		position: absolute;
		left: -1px;
		right: -1px;
		top: 100%;
		padding: 24px 0 0;
		opacity: 0;
		pointer-events: none;
		transform: translateY(-20px);
		transition: opacity $animation-speed $animation-effect, transform $animation-speed $animation-effect;
	}

	@include media('<desktop') {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s $animation-effect;
	}

	.opened & {
		@include media('<desktop') {
			max-height: 562px;
		}
	}
}

.nav-drop-menu {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1px;
	padding: 18px 0;
	font-size: 16px;
	line-height: 24px;
	border: 1px solid $alt-border-color;
	border-radius: 10px;
	box-shadow: 0 4px 20px rgba($black, 0.1);
	background: $white;
	transition: background-color $animation-speed $animation-effect, border-color $animation-speed $animation-effect;

	@include media('<desktop') {
		margin: 0;
		padding: 10px 0 8px;
		border: 0;
		box-shadow: none !important;
		background: none !important;
	}

	li {
		display: flex;
		width: 100%;
		padding: 0 0 16px;

		@include media('>=desktop') {
			flex: 0 1 33.3%;
			width: 33.3%;
			padding: 5px 25px;
		}

		&:before {
			display: none;
		}
	}

	a {
		display: flex;
		align-items: flex-start;
		width: 100%;
		padding: 16px 16px 8px;
		border-radius: 8px;
	}

	.active a,
	a:hover {
		background: rgba($black, 0.05);

		i {
			color: inherit;
		}
	}

	i {
		margin: 0 10px 0 0;
		font-size: 32px;
		color: $base-link-color;
		transition: color $animation-speed $animation-effect;
	}

	.text-box {
		padding: 4px 0 0;
	}

	strong {
		display: block;
		padding: 0 0 8px;
		font-size: 20px;
	}

	span {
		color: $icon-muted-color;
		transition: color $animation-speed $animation-effect;
	}
}

.add-actions-block {
	display: flex;
	align-items: center;
	white-space: nowrap;

	@include media('<desktop') {
		width: 100%;
		padding: 15px 6px;
	}

	@include media('<phone') {
		flex-direction: column-reverse;
	}

	.btn {
		padding: 9px 24px;
		text-decoration: none;
		border-radius: 8px;
	}

	.link-sign {
		margin: 0 45px 0 0;
		color: inherit;
		text-align: center;

		@include media('<desktop') {
			line-height: 28px !important;
			text-decoration: none;
			border: 2px solid currentColor;
			border-radius: 8px;
		}
	}

	.link-sign,
	.btn {
		@include media('<desktop') {
			padding: 14px 16px;
			width: calc(50% - 20px);
			margin: 10px;
			line-height: 32px;
		}

		@include media('<phone') {
			width: calc(100% - 20px);
		}
	}
}