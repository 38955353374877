// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

.mobile-visible {
  @include media('>=tablet') {
		display: none !important;
	}
}

.mobile-hidden {
  @include media('<tablet') {
		display: none !important;
	}
}


// transition
%transition {
 transition: all $animation-speed $animation-effect;
}

// justify nav
%justify {
 text-align: justify;
 > * {
  display: inline-block;
  vertical-align: top;
  text-align: left;
 }
 &:after {
  content: '';
  width: 100%;
  display: inline-block;
  vertical-align: top;
 }
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

/* Animation keyframes */
@keyframes xAxis {
	50% {
		animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
		transform: translateX(10%);
	}
}

@keyframes yAxis {
	50% {
		animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
		transform: translateY(-10%);
	}
}

@keyframes xAxisOpposite {
	50% {
		animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
		transform: translateX(-10%);
	}
}

@keyframes yAxisOpposite {
	50% {
		animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
		transform: translateY(10%);
	}
}

@keyframes transparency {
	30% {
		animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
		opacity: 0.7;
	}
	80% {
		animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
		opacity: 0.4;
	}
}

@keyframes transparencyOpposite {
	30% {
		animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
		opacity: 0.4;
	}
	80% {
		animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
		opacity: 0.8;
	}
}