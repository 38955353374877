// Typography

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?fdo5vd');
  src: url('../fonts/icomoon.eot?fdo5vd#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fdo5vd') format('truetype'),
    url('../fonts/icomoon.woff?fdo5vd') format('woff'),
    url('../fonts/icomoon.svg?fdo5vd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-team:before {
  content: "\e90b";
}

.icon-arrow-right:before {
  content: "\e900";
}

.icon-chart:before {
  content: "\e903";
}

.icon-check:before {
  content: "\e904";
}

.icon-chevron-down:before {
  content: "\e905";
}

.icon-clock:before {
  content: "\e906";
}

.icon-group:before {
  content: "\e907";
}

.icon-moon:before {
  content: "\e908";
}

.icon-sun:before {
  content: "\e909";
}

.icon-thumb-up:before {
  content: "\e90a";
}

.icon-link:before {
  content: "\e918";
}

.icon-twitter:before {
  content: "\e901";
}

.icon-facebook:before {
  content: "\e902";
}

.icon-linkedin:before {
  content: "\eac9";
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
  display: block;
  font-family: $headings-font-family;
  font-weight: bold;
  margin: 0 0 0.5em;
  color: $headings-color;
  line-height: 1.25;
}

h1, .h1 {
  margin: 0 0 28px;
  font-size: $h1-font-size;
  line-height: 68px;

  @include media('<tablet') {
    margin: 0 0 21px;
    font-size: 36px;
    line-height: 43px;
  }
}

h2, .h2 {
  margin: 0 0 22px;
  font-size: $h2-font-size;
  line-height: 58px;

  @include media('<tablet') {
    margin: 0 0 21px;
    font-size: 28px;
    line-height: 34px;
  }
}

h3, .h3 {
  margin: 0 0 16px;
  font-size: $h3-font-size;
  line-height: 38px;

  @include media('<tablet') {
    margin: 0 0 19px;
    font-size: 26px;
    line-height: 31px;
  }
}

h4, .h4 {
  margin: 0 0 14px;
  font-size: $h4-font-size;
  line-height: 32px;

  @include media('<tablet') {
    font-size: 24px;
    line-height: 28px;
  }
}

h5, .h5 {
  margin: 0 0 8px;
  font-size: $h5-font-size;

  @include media('<tablet') {
    font-size: 22px;
  }
}

h6, .h6 {
  margin: 0 0 4px;
  font-size: $h6-font-size;
}

ul, ol, p, blockquote, pre {
  & + h2 {
    margin-top: 45px;
  }

  & + h3 {
    margin-top: 38px;
  }

  & + h4 {
    margin-top: 34px;
  }
}

p {
  margin: 0 0 21px;
}

a {
  color: $base-link-color;
  text-decoration: none;

  &:hover{
    color: $base-link-hover-color;
    text-decoration: underline;
  }
}

blockquote, blockquote:before, blockquote:after, q, q:before, q:after {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
}

blockquote {
  margin: 0 0 21px;
  padding: 2px 0 2px 17px;
  line-height: 33px;
  border-left: 3px solid $primary;

  p {
    margin: 0;
  }
}

cite {
  display: block;
  font-size: 16px;

  &:before {
    display: inline;
    content: '– ';
  }
}

pre,
.pre {
  overflow: auto;
  padding: 12px 20px;
  background-color: #f1f6fd;
}

ul, ol {
  margin: 0;
  padding: 0 0 16px;
  list-style: none;

  li {
    position: relative;
    padding: 0 0 10px 20px;

    &:before {
      position: absolute;
      content: '•';
      left: 0;
      font-size: 1.2em;
      line-height: 1.5;
    }
  }

  ul {
    li:before {
      content: '⚬';
      font-size: 1em;
      line-height: 1.75;
    }

    ul {
      li:before {
        content: '■';
        font-size: 0.7em;
        line-height: 2.5;
      }

      ul {
        li:before {
          content: '□';
          font-size: 0.7em;
          line-height: 2.5;
        }
      }
    }
  }
}

ol {
  counter-reset: item;

  > li:before {
    counter-increment: item;
    content: counter(item) '. ';
    font-size: inherit;
    line-height: inherit;
  }
}

table {
  width: 100%;
  margin: 0 0 24px;
  border-collapse: collapse;

  tr {
    &:nth-child(odd) {
      th, td {
        background: $alt-background-color;
      }
    }
  }

  th, td {
    padding: 4px 8px;
    text-align: left;
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}