/// colors black & white
/// @example scss - Usage
///   .my-class {
///     background: black(0.15);
///     color: white(0.9);
///   }
@function black($opacity){
  @return rgba(black, $opacity);
}

@function white($opacity){
  @return rgba(white, $opacity);
}

