html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
  min-width: $base-min-width;
  overflow: auto;
  margin: 0;
  color: $body-color;
  background: $base-background-color;
  font: #{$base-font-size}/#{$base-line-height} $base-font-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media('<tablet') {
    font-size: 16px;
    line-height: 26px;
  }
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align:top;

  &.img-responsive {
    display: block;
    margin: 0 auto;
  }
}

// google map fix
a, button, input[type="submit"]{
	cursor:pointer;
	transition: color $animation-speed $animation-effect, background-color $animation-speed $animation-effect, opacity $animation-speed $animation-effect, border-color $animation-speed $animation-effect, visibility $animation-speed $animation-effect, transform $animation-speed $animation-effect;
}

hr {
  @include size(100%, 1px);
  margin: 0 0 67px;
  background: currentColor;
  opacity: 0.15;
}