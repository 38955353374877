@mixin benefits-list($main-color: $primary) {
	padding: 0;
	font-size: 16px;
	line-height: 24px;

	&.large {
		li {
			padding-left: 34px;

			&:before {
				@include size(24px);
				top: 1px;
				font-size: 13px;
			}
		}
	}

	li {
		padding: 0 0 20px 28px;

		&:last-child {
			padding-bottom: 0;
		}

		&:before {
			@include add-icon("\e904"); // icon-check
			@include size(18px);
			display: flex;
			align-items: center;
			justify-content: center;
			top: 4px;
			font-size: 10px;
			color: $white;
			border-radius: 50%;
			background: $main-color;
		}
	}
}

.benefits-list {
	@include benefits-list();
}