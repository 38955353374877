/* Footer styles */
.footer {
	flex-shrink: 0;
	padding: 140px 0;
	line-height: 24px;
	color: $white;
	background: $dark-background-color;

	@include media('<desktop') {
		padding: 40px 0;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.logo {
		margin: 0 0 27px;
	}
}

.bottom-box {
	flex: none;
	width: 285px;
	margin: 0 148px 20px 0;

	@include media('<widescreen') {
		margin: 0 60px 20px 0;
	}

	@include media('<desktop') {
		width: 100%;
		margin: 0 0 26px;
		font-size: 16px;
	}

	p {
		opacity: 0.6;
	}
}

.subnav {
	flex: none;
	width: calc(100% - 433px);
	display: flex;
	flex-wrap: wrap;
	padding: 11px 0 0;

	@include media('<desktop') {
		width: calc(100% + 20px);
		margin: 0 -10px 10px;
	}

	.subnav-col {
		width: 33.3%;
		padding: 0 10px;

		@include media('<desktop') {
			width: 50%;
			padding-bottom: 44px;
		}
	}

	strong {
		display: block;
		padding: 0 0 28px;
		opacity: 0.3;
	}

	ul {
		padding: 0;

		li {
			padding: 0 0 16px;

			&:last-child {
				padding-bottom: 0;
			}

			&:before {
				display: none;
			}
		}
	}

	a {
		color: inherit;
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}
}

.copyrights-box {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	width: 100%;

	@include media('>=desktop') {
		padding-top: 64px;
	}

	@include media('<phone') {
		flex-direction: column;
	}
}

.copyrights {
	font-size: 16px;
	opacity: 0.4;
}

.made-frame {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	color: rgba($white, 0.6);
}