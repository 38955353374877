@import 'abstracts/variables';
@import 'vendor/include-media';

@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'base/helpers';
@import 'base/reset';
@import 'base/typography';
@import 'base/forms';
@import 'components/index';

.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	overflow: clip;
	min-height: 100vh;
}

.container {
	max-width: 1232px;
	margin: 0 auto;
	padding: 0 16px;

	&.reduced {
		max-width: 848px;
	}

	&:has(> .aside) {
		display: flex;
		flex-direction: row-reverse;
		gap: 100px;

		@include media('<widescreen') {
			gap: 40px;
		}
	}
}

.main {
	flex-grow: 1;
	position: relative;
	z-index: 1;
	padding: 0 0 150px;
	background: $alt-background-color;

	@include media('<tablet') {
		padding: 0 0 80px;
	}

	&.accent {
		padding: 196px 0 80px;
		color: $white;
		background: #282C33;

		@include media('<desktop') {
			padding: 130px 0 80px;
		}

		@include media('<tablet') {
			padding: 96px 0 30px;
		}

		h1 {
			margin: 0 0 90px;

			@include media('<tablet') {
				margin: 0 0 50px;
			}
		}
	}
}

.main-block {
	padding: 147px 0;

	@include media('<tablet') {
		padding: 100px 0 0;
	}
}

.aside {
	flex: 0 0 304px;
	position: relative;

	@include media('<desktop') {
		display: none;
	}
}

.anchors-menu-block {
	--top-offset: 100px;
	display: flex;
	flex-direction: column;
	position: sticky;
	top: var(--top-offset);
	max-height: calc(100dvh - var(--top-offset));

	h4 {
		flex-shrink: 0;
	}
}

.anchors-menu {
	@include scrollbars();
	flex-grow: 1;
	overflow: auto;
	margin: 0 0 0 -10px;
	padding: 14px 0 0;
	list-style: none;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;

	li {
		padding: 0 0 15px;

		&:last-child {
			padding-bottom: 0;
		}

		&:before {
			display: none;
		}
	}

	a {
		display: block;
		position: relative;
		padding-left: 16px;
		color: inherit;
		text-decoration: none;

		&:before {
			position: absolute;
			content: "";
			inset: 0 auto 0 0;
			width: 3px;
			background: currentColor;
			opacity: 0;
			transition: opacity $animation-speed $animation-effect;
		}
	}

	.active a,
	a:hover,
	a:focus-visible {
		color: $base-link-color;
	}

	a:target,
	.active a {
		&:before {
			opacity: 1;
		}
	}
}

.heading-box {
	max-width: 1100px;
	margin: 0 auto 40px;
	text-align: center;

	@include media('<tablet') {
		margin-bottom: 14px;
	}

	&.align-left {
		margin-left: 0;
		text-align: left;
	}

	h1 {
		font-weight: 900;
		color: $alt-text-color;

		@include media('>=tablet') {
			margin: 0 0 22px;
			font-size: 72px;
			line-height: 83px;
		}
	}

	h2 {
		margin: 0 0 14px;
		color: $alt-text-color;
	}

	.slogan {
		display: block;
		max-width: 816px;
		margin: 0 auto;
		font-size: 22px;
		line-height: 35px;
		font-weight: normal;
		color: $text-muted-color;

		@include media('<tablet') {
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.blogs-container {
	.notes {
		display: none;
		color: $placeholder-color;
	}

	> [style*="height: 0px;"] {
		+ .notes {
			display: block;
		}
	}
}

.filters-list {
	margin: 0 -10px 47px;
	padding: 0;
	font-size: 0;
	line-height: 0;
	text-align: center;

	@include media('<tablet') {
		margin: 0 -5px 14px;
	}

	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 10px 10px;

		@include media('<tablet') {
			padding: 0 5px 8px;
		}

		&:before {
			display: none;
		}
	}

	button {
		display: block;
		padding: 9px 24px;
		font-size: 18px;
		line-height: 32px;
		font-weight: bold;
		color: $body-color;
		outline: none;
		border: 0;
		border-radius: 8px;
		background: none;

		@include media('<tablet') {
			padding: 6px 16px;
			font-size: 14px;
			line-height: 26px;
		}

		&:focus {
			outline: 1px solid rgba($primary, 0.4);
		}

		&:hover {
			outline: none;
			background: lighten($alt-background-color, 2%);
		}

		&.is-checked {
			outline: none;
			background: $alt-background-color;
		}
	}
}

.blogs-holder {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin: 0 -21px;
	line-height: 24px;

	.blog-block {
		width: 100%;
		padding: 0 21px 57px;

		@include media('>=tablet') {
			width: 50%;
		}

		@include media('>=desktop') {
			width: 33.33%;
		}

		> .holder {
			max-width: 380px;
			margin: 0 auto;
		}
	}

	img {
		width: 100%;
		margin: 0 0 19px;
		border-radius: 20px;

		@include media('<tablet') {
			margin: 0 0 12px;
		}
	}

	h4 {
		margin: 0 0 12px;
		color: $alt-text-color;

		a {
			color: inherit;
		}
	}

	p {
		margin: 0 0 14px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;

		&:last-child {
			margin: 0;
		}
	}
}

.category {
	display: block;
	padding: 0 0 15px;
	font-size: 20px;
	line-height: 24px;
	color: $base-link-color;

	@include media('<tablet') {
		padding: 0 0 10px;
		font-size: 16px;
		line-height: 20px;
	}
}

.post-author {
	display: flex;
	align-items: center;
	font-size: 13px;
	line-height: 16px;

	&.large {
		padding: 20px 0 30px;
		font-size: 16px;
		line-height: 24px;

		.avatar-box {
			@include size(72px);
			margin: 0 24px 0 0;
		}

		strong {
			padding: 0 0 8px;
			font-size: 20px;
			line-height: 22px;
		}
	}

	.avatar-box {
		@include size(40px);
		flex-shrink: 0;
		overflow: hidden;
		margin: 0 16px 0 0;
		border-radius: 50%;
		
		img {
			@include size(100%);
			object-fit: cover;
			border-radius: 50%;
		}
	}

	.text-box {
		flex-grow: 1;
	}

	span,
	strong {
		display: block;
	}

	strong {
		padding: 0 0 4px;
		font-size: 16px;
		line-height: 17px;
		color: $base-link-color;
	}
}

.related-topics {
	padding: 120px 0 100px;
	background: $alt-background-color;

	@include media('<desktop') {
		padding: 54px 0 50px;
	}

	h2 {
		margin: 0 0 32px;
		color: $alt-text-color;
	}
}

.price-blocks {
	margin: 0 -17px 70px -18px;
	font-size: 16px;
	line-height: 24px;

	@include media('>=desktop') {
		display: flex;
	}
}

.price-block {
	padding: 0 17px 30px 18px;

	@include media('<desktop') {
		max-width: 520px;
	}

	@include media('>=desktop') {
		flex-shrink: 0;
		width: 33.33%;
	}

	&.blured {
		> .holder{
			&:after {
				position: absolute;
				content: '';
				z-index: 2;
				left: 0;
				right: 0;
				top: 130px;
				bottom: 0;
				border-top: 1px solid rgba($white, 0.05);
				border-radius: 0 0 7px 7px;
				background: rgba(54, 55, 61, 0.3);
				backdrop-filter: blur(15px);
			}

			&:after,
			> * {
				pointer-events: none;
				user-select: none;
				-khtml-user-select: none;
				-webkit-user-select: none;
			}
		}
	}

	@each $block-color in ('blue', 'green', 'pink') {
		&.#{$block-color}-mode {
			@if $block-color == 'blue' {
				$block-color: $blue;
			} @else if $block-color == 'green' {
				$block-color: $green;
			} @else if $block-color == 'pink' {
				$block-color: $pink;
			}

			> .holder {
				&:before {
					background: $block-color;
				}
			}

			.btn-action {
				@include coloured-button($block-color);
			}

			.benefits-list li:before {
				background: $block-color;
			}
		}
	}

	> .holder {
		position: relative;
		min-height: 100%;
		padding: 32px 30px 24px;
		border-radius: 7px;
		box-shadow: 0 6px 40px rgba($black, 0.12);
		background: #2C3138;
		
		&:before {
			@include size(100%, 7px);
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			border-radius: 7px 7px 0 0;
			background: rgba($white, 0.4);
		}
	}

	h3 {
		margin: 0 0 20px;

		@include media('<tablet') {
			margin: 0 0 14px;
		}
	}

	.sub-text {
		display: block;
		padding: 0 0 22px;
		opacity: 0.8;

		@include media('<tablet') {
			margin: 0 0 8px;
		}
	}

	.price {
		display: block;
		padding: 0 0 33px;
		font-size: 56px;
		line-height: 67px;
		font-style: normal;
		font-weight: bold;

		@include media('<tablet') {
			padding: 0 0 20px;
			font-size: 44px;
			line-height: 57px;
		}

		small {
			font-size: 16px;
			font-weight: normal;
			color: $text-muted-color;
		}
	}

	.btn-action {
		min-width: 170px;
		margin-bottom: 35px;
	}

	hr {
		margin-bottom: 28px;

		@include media('<tablet') {
			margin-bottom: 20px;
		}
	}

	.h6 {
		margin: 0 0 15px;
		font-weight: normal;
	}

	.benefits-list {
		color: rgba($white, 0.8);
	}
}

.skeleton {
	display: inline-block;
	position: relative;
	top: 0.2em;
	height: 0.5em;
	overflow: hidden;
	margin-bottom: 0.2em;
	border-radius: 100px;
	color: var(--skeleton-color, inherit);
	background: var(--skeleton-color, currentColor);
}

.faq-section {
	@include media('>=tablet') {
		font-size: 20px;
		line-height: 30px;
	}

	h2 {
		margin: 0 0 48px;
	}

	> .holder {
		margin: 0 -17px 0 -18px;
		
		@include media('>=tablet') {
			display: flex;
			flex-wrap: wrap;
		}
	}
}

.faq-block {
	flex: none;
	padding: 20px 17px 41px 18px;

	@include media('>=tablet') {
		width: 50%;
	}

	@include media('>=desktop') {
		width: 33.33%;
	}

	h4 {
		margin: 0 0 22px;
	}

	p {
		opacity: 0.7;

		&:last-child {
			margin: 0;
		}
	}
}

.apply-form-holder {
	padding: 10px 0 92px;

	@include media('>=tablet') {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		font-size: 19px;
		line-height: 26px;
	}

	@include media('>=desktop') {
		font-size: 22px;
		line-height: 30px;
	}

	@each $block-color in ('blue', 'green', 'pink') {
		&.#{$block-color}-mode {
			@if $block-color == 'blue' {
				$block-color: $blue;
			} @else if $block-color == 'green' {
				$block-color: $green;
			} @else if $block-color == 'pink' {
				$block-color: $pink;
			}

			.benefits-list li:before {
				background: $block-color;
			}
		}
	}

	.text-block {
		padding: 0 0 50px;

		@include media('>=tablet') {
			width: 50.8%;
		}

		h1 {
			margin: 0 0 28px;
		}
	}

	p {
		margin: 0 0 40px;
		opacity: 0.8;
	}

	.benefits-list {
		color: rgba($white, 0.8);
	}

	.form-holder {
		padding: 26px 20px 28px;
		color: $alt-text-color;
		border-radius: 15px;
		background: $white;

		@include media('>=tablet') {
			width: 42%;
			margin: 10px 0 0;
			padding: 36px 32px 38px;
		}

		@include media('>=desktop') {
			padding: 36px 48px 38px;
		}

		.sib-form,
		#sib-container,
		.sib-form-block {
			padding: 0;
		}

		.entry__label {
			margin-bottom: 4px;
		}

		.sib-form-block__button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			text-align: center;
		}

		.sib-form .entry__error {
			color: $error-text-color;
			background: none;
		}

		.notes {
			display: block;
			padding: 8px 0 0;
			font-size: 12px;
			line-height: 18px;
			color: rgba($body-color, 0.8);
		}
	}
}

.welcome-block {
	position: relative;
	padding: 170px 0 134px;
	font-size: 20px;
	line-height: 30px;
	color: $white;
	text-align: center;
	background: $dark-background-color;

	@include media('<tablet') {
		padding: 111px 0 72px;
		font-size: 18px;
		line-height: 24px;
	}

	.bg-anim-holder {
		@include size(100%);
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		overflow: hidden;
		pointer-events: none;

		&:after {
			@include size(100%);
			position: absolute;
			content: '';
			z-index: 1;
			left: 0;
			top: 0;
			backdrop-filter: blur(100px);
		}

		.circle {
			position: absolute;
			animation:
				xAxis 30s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 30s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 12s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				@include size(100%);
				position: absolute;
				content: '';
				left: 0;
				top: 0;
				border-radius: 50%;
			}
		}

		.circle-anim01 {
			@include size(24.28vw);
			left: -20.29%;
			top: 38.93%;
			animation:
				xAxisOpposite 40s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 40s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 14s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(328.41deg, rgba(218, 37, 111, 0.45) -87.6%, rgba(218, 37, 111, 0) 102.89%);
				transform: rotate(15deg);
			}
		}

		.circle-anim02 {
			@include size(38.31vw);
			left: 2.18%;
			top: 10.06%;
			animation:
				xAxisOpposite 38s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 38s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 13s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(323.56deg, rgba(218, 37, 111, 0.45) -90.53%, rgba(218, 37, 111, 0) 58.18%);
				transform: rotate(65.5deg);
			}
		}

		.circle-anim03 {
			@include size(34.81vw);
			left: 82.78%;
			top: 8.32%;
			animation:
				xAxis 26s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 26s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 9s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(293.43deg, #23272D 35.35%, rgba(35, 39, 45, 0) 81.84%);
				transform: rotate(-120deg);
			}
		}

		.circle-anim04 {
			@include size(31.02vw);
			left: 84.09%;
			top: 23.07%;
			animation:
				xAxis 32s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 32s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 15s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(293.43deg, #23272D 35.35%, rgba(35, 39, 45, 0) 81.84%);
				transform: rotate(120deg);
			}
		}

		.circle-anim05 {
			@include size(22.26vw);
			left: 15.06%;
			top: -13.37%;
			animation:
				xAxisOpposite 44s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 44s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 12s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(7.24deg, #23272D -82.8%, rgba(35, 39, 45, 0) 78.76%);
				transform: rotate(52.08deg);
			}
		}

		.circle-anim06 {
			@include size(22.26vw);
			left: -6.71%;
			top: -2.67%;
			animation:
				xAxisOpposite 42s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 40s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 16s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(17.42deg, #6FDA25 -307.5%, rgba(111, 218, 37, 0) 91.02%);
				transform: rotate(52.08deg);
			}
		}

		.circle-anim07 {
			@include size(22.26vw);
			left: 34.38%;
			top: 21.3%;
			animation:
				xAxis 40s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 40s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 14s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(334.39deg, rgba(111, 218, 37, 0.74) -211.15%, rgba(111, 218, 37, 0) 72.52%);
				transform: rotate(52.08deg);
			}
		}

		.circle-anim08 {
			@include size(22.26vw);
			left: 55.27%;
			top: -20.17%;
			animation:
				xAxis 38s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 38s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 11s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(335.38deg, #6FDA25 -212.87%, rgba(111, 218, 37, 0) 81.84%);
				transform: rotate(52.08deg);
			}
		}

		.circle-anim09 {
			@include size(29.06vw);
			left: 53.44%;
			top: 8.91%;
			animation:
				xAxis 39s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 41s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 11s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(323.33deg, rgba(111, 218, 37, 0.37) -138.2%, rgba(111, 218, 37, 0) 61.78%);
				transform: rotate(52.08deg);
			}
		}

		.circle-anim10 {
			@include size(27.86vw);
			left: 22.44%;
			top: -15.43%;
			animation:
				xAxisOpposite 34s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 35s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 16s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(201.46deg, rgba(218, 37, 111, 0.45) -48.03%, rgba(218, 37, 111, 0) 66.17%);
			}
		}

		.circle-anim11 {
			@include size(31.14vw);
			left: 15.56%;
			top: -7.31%;
			animation:
				xAxis 32s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 34s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 11s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(200.06deg, rgba(218, 37, 111, 0.45) -116.76%, rgba(218, 37, 111, 0) 66.29%);
				transform: rotate(118.48deg);
			}
		}

		.circle-anim12 {
			@include size(31.14vw);
			left: -15.57%;
			top: 21.39%;
			animation:
				xAxisOpposite 48s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 38s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 15s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(200.06deg, rgba(218, 37, 111, 0.45) -116.76%, rgba(218, 37, 111, 0) 66.29%);
			}
		}

		.circle-anim13 {
			@include size(48.54vw);
			left: 50.31%;
			top: -19.42%;
			animation:
				xAxis 48s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 44s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 15s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(205.58deg, #DA256F -99.28%, rgba(218, 37, 111, 0) 64.42%);
			}
		}

		.circle-anim14 {
			@include size(50.72vw);
			left: 20.48%;
			top: 77%;
			animation:
				xAxisOpposite 32s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 36s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 16s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(179.43deg, rgba(28, 108, 228, 0.12) -37.52%, rgba(28, 108, 228, 0) 64.72%);
				transform: rotate(-151.63deg);
			}
		}

		.circle-anim15 {
			@include size(35.93vw);
			left: 65.26%;
			top: -4.36%;
			animation:
				xAxis 28s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 35s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparencyOpposite 12s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(173.57deg, #6FA8FF -89.03%, rgba(28, 108, 228, 0) 68.15%);
				transform: rotate(42.6deg);
			}
		}

		.circle-anim16 {
			@include size(36.77vw);
			left: -1.18%;
			top: 0.58%;
			animation:
				xAxisOpposite 37s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxisOpposite 38s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 12s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(151.28deg, #1C6CE4 -91.1%, rgba(28, 108, 228, 0) 53.06%);
				transform: rotate(-3.97deg);
			}
		}

		.circle-anim17 {
			@include size(58.9vw);
			left: -23.91%;
			top: -22.38%;
			animation:
				xAxis 45s infinite cubic-bezier(0.02, 0.01, 0.21, 1),
				yAxis 43s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64),
				transparency 22s infinite cubic-bezier(0.02, 0.01, 0.21, 1);

			&:after {
				background: linear-gradient(157.09deg, #1C6CE4 -53.99%, rgba(28, 108, 228, 0) 51.13%);
				transform: rotate(-2.8deg);
			}
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}

	h1 {
		font-weight: 900;
		max-width: 1000px;

		@include media('>=tablet') {
			margin: 0 auto 15px;
			font-size: 64px;
			line-height: 77px;
		}
	}

	.description {
		max-width: 686px;
		margin: 0 auto 36px;
		opacity: 0.8;

		@include media('<tablet') {
			margin-bottom: 30px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	.slogan {
		font-size: 22px;
		line-height: 35px;
		font-weight: normal;

		@include media('<tablet') {
			font-size: 20px;
			line-height: 30px;
		}
	}

	.add-actions-block {
		width: auto;
		margin: -10px -10px 66px;
		padding: 0;
		color: $white;
	}

	.sup-title {
		display: block;
		padding: 0 0 15px;
		line-height: 24px;
		color: $placeholder-color;

		@include media('<tablet') {
			padding: 0 0 7px;
			font-size: 16px;
			line-height: 19px;
		}
	}

	h2 {
		@include media('>=tablet') {
			margin: 0 0 25px;
		}
	}

	.blocks-holder {
		margin: 0 -15px;
		padding: 14px 0 0;

		@include media('>=desktop') {
			display: flex;
		}
	}

	.preview-block {
		width: 33.33%;
		padding: 0 15px 16px;
		text-align: left;

		@include media('<desktop') {
			width: auto;
			max-width: 420px;
			margin: 0 auto;
		}

		@include media('>=tablet') {
			&:nth-child(1) {
				a:before {
					background: linear-gradient(145.54deg, #333C4E 12.68%, #234374 149.39%);
				}
			}

			&:nth-child(2) {
				a:before {
					background: linear-gradient(148.25deg, #333C4E 9.8%, #2E481C 107.89%);
				}
			}

			&:nth-child(3) {
				a:before {
					background: linear-gradient(145.54deg, #333C4E 12.68%, #6C2542 149.39%);
				}
			}
		}

		a {
			display: block;
			position: relative;
			min-height: 100%;
			padding: 40px 30px 67px;
			color: rgba($white, 0.8);
			text-decoration: none;
			border: 1px solid #32373E;
			border-radius: 20px;
			background: #282C33;

			&:before {
				@include media('>=tablet') {
					@include size(100%);
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					border-radius: 19px;
					opacity: 0;
					pointer-events: none;
					transition: opacity $animation-speed $animation-effect;
				}

			}

			&:hover {
				&:before {
					@include media('>=tablet') {
						opacity: 1;
					}
				}
			}

			> * {
				position: relative;
				z-index: 1;
			}
		}

		img {
			margin: 0 0 67px;
		}

		h4 {
			margin: 0 0 20px;
			color: $white;
		}

		.action-text {
			position: absolute;
			left: 29px;
			bottom: 32px;
			color: $white;
		}
	}
}

.theme-preview {
	padding: 0 0 145px;

	.img-holder {
		position: relative;
	}

	img {
		position: relative;
		opacity: 0;
		transition: opacity $animation-speed * 1.5 $animation-effect;
	}

	.dark-image {
		position: absolute;
		left: 0;
		top: 0;
	}

	&[data-mode="light-mode"] {
		.light-image {
			opacity: 1;
		}
	}

	&[data-mode="dark-mode"] {
		.dark-image {
			opacity: 1;
		}
	}
}

.theme-switchers-form {
	@include size(85px, 44px);
	display: flex;
	position: relative;
	margin: 0 auto 22px;
	border-radius: 22px;
	background: #2C3138;

	label {
		@include size(50%, 100%);
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;
		padding: 0 0 0 2px;
		pointer-events: none;

		& ~ label {
			padding: 0 2px 0 0;
		}
	}

	input {
		@include size(100%);
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		margin: 0;
		opacity: 0;
		cursor: pointer;

		&:checked {
			z-index: auto;

			& + label i {
				color: #2C3138;
			}
		}

		& ~ input {
			&:checked {
				& ~ span {
					transform: translateX(40px);
				}
			}
		}
	}

	i {
		font-size: 18px;
		color: $icon-muted-color;
		transition: color $animation-speed * 1.5 $animation-effect;
	}

	span {
		@include size(36px);
		position: absolute;
		left: 4px;
		top: 4px;
		border-radius: 50%;
		background: #E8EFF7;
		transition: transform $animation-speed * 1.5 $animation-effect;
		pointer-events: none;
	}
}

.section {
	padding: 139px 0 0;

	@include media('<tablet') {
		padding: 75px 0 0;
	}

	.sup-heading {
		display: block;
		padding: 0 0 5px;
		font-size: 20px;
		line-height: 36px;
		font-weight: normal;
		color: $placeholder-color;

		@include media('<tablet') {
			padding: 0 0 3px;
			font-size: 16px;
			line-height: 19px;
		}
	}
}

.articles-container {
	--img-side-indent: 120px;

	flex-wrap: wrap;
	margin: 0 -15px;
	font-size: 20px;
	line-height: 30px;
	color: $text-muted-color;

	@include media('<tablet') {
		--img-side-indent: 20px;
		font-size: 16px;
		line-height: 24px;
	}

	@include media('>=tablet') {
		display: flex;
	}

	.article {
		flex-shrink: 0;
		display: flex;
		width: 54.88%;
		padding: 30px 15px 0;

		@include media('<tablet') {
			display: block;
			width: auto;
			padding-top: 16px;
		}

		&.full-size {
			width: 100%;
		}

		&.wide {
			@include media('>=tablet') {
				width: 100%;
			}

			&[class*="-mode"] {
				.article-holder {
					padding: 0;
				}
			}

			.article-holder {
				@include media('>=tablet') {
					display: flex;
					width: 100%;
				}

				&.flipped {
					flex-direction: row-reverse;

					.image-frame {
						@include media('>=tablet') {
							padding-right: 0;
						}
					}
				}
					
				&:not(.flipped) {
					.image-frame {
						@include media('>=tablet') {
							padding-left: 0;
						}
					}
				}
			}

			.text-block {
				@include media('>=tablet') {
					width: 42%;
				}

				&.alt-size {
					@include media('>=tablet') {
						width: 38.8%;
					}
				}
			}

			.image-frame {
				@include media('>=tablet') {
					width: 58%;
				}

				&.alt-size {
					@include media('>=tablet') {
						width: 61.2%;
					}
				}
			}
		}

		&.small {
			@include media('>=tablet') {
				width: 45.12%;
			}
		}

		&[class*="-mode"] {
			color: $white;
			
			.article-holder {
				padding: 50px 60px 61px;

				@include media('<tablet') {
					padding: 18px 24px 29px;
				}
			}

			img {
				display: block;
				margin: 0 auto 26px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			h3, a {
				color: inherit;
			}
		}

		&.blue-mode {
			.article-holder {
				background: $primary linear-gradient(146.57deg, #1C6CE4 0.76%, #010813 100%);
			}
		}

		&.turquoise-mode {
			.article-holder {
				background: $turquoise linear-gradient(146.06deg, #11F3CB 0%, #011E39 100%);
			}
		}

		&.green-mode {
			.article-holder {
				background: $green linear-gradient(158.08deg, #94E619 -11.27%, #042903 93.55%);

			}
		}

		&.pink-mode {
			.article-holder {
				background: $pink linear-gradient(134.07deg, #EE2276 0%, #23176D 100.87%);
			}
		}

		&.purple-mode {
			.article-holder {
				background: $purple linear-gradient(146.57deg, #A41CE4 0.76%, #1E032B 100%);

			}
		}

		&.orange-mode {
			.article-holder {
				background: $orange linear-gradient(145.05deg, #EE4111 2.55%, #702510 84.64%);


			}
		}
	}

	.article-holder {
		overflow: hidden;
		border-radius: 20px;
		background: #F7F8FA;
	}

	.text-block {
		padding: 50px 60px 30px;

		@include media('<desktop') {
			padding: 30px 40px 20px;
		}

		@include media('<tablet') {
			padding: 17px 24px 30px;
		}
	}

	.alignleft,
	.alignright {
		@include media('<phone') {
			margin-bottom: var(--img-side-indent);
		}
	}

	.alignleft {
		@include media('>=phone') {
			margin-right: var(--img-side-indent);
			float: left;
		}
	}

	.alignright {
		@include media('>=phone') {
			margin-left: var(--img-side-indent);
			float: right;
		}
	}

	.text-holder {
		overflow: hidden;
	}

	h3 {
		margin: 0 0 28px;
		color: $alt-text-color;

		@include media('<tablet') {
			margin: 0 0 15px;
		}

		&:last-child {
			margin-bottom: 0 !important;
		}

		a {
			color: inherit;
		}
	}

	p {
		@include media('<tablet') {
			margin: 0 0 16px;
		}
	}

	.image-frame {
		display: flex;
		align-items: flex-start;
		padding: 60px;

		@include media('<desktop') {
			padding: 40px;
		}

		@include media('<tablet') {
			padding: 0 24px 24px;
		}

		&.no-pd-l {
			padding-left: 0 !important;
		}

		&.no-pd-t {
			padding-top: 0 !important;
		}

		&.no-pd-r {
			justify-content: flex-end;
			padding-right: 0 !important;
		}

		&.no-pd-b {
			align-items: flex-end;
			padding-bottom: 0 !important;
		}
	}
}

.theming-image-holder {
	position: relative;

	body:has([data-mode="dark-mode"]) & {
		img {
			&:first-child {
				opacity: 0;
			}

			&:last-child {
				opacity: 1;
			}
		}
	}

	img {
		transition: opacity $animation-speed $animation-effect;

		&:last-child {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
	}
}

.promo-block {
	font-size: 22px;
	line-height: 35px;
	color: $white;
	background: $dark-background-color linear-gradient(104.6deg, #23272E 25.93%, #00040E 100%);


	> .container {
		display: flex;
		align-items: center;
		padding-top: 189px;
		padding-bottom: 117px;
		min-height: 876px;
	}

	.text-holder {
		width: 43.75%;
	}

	h1 {
		margin: 0 0 18px;
	}

	p {
		margin: 0 0 34px;
		opacity: 0.8;
	}

	.img-holder {
		flex-grow: 1;
		text-align: right;
	}

	a {
		color: inherit;
	}
}