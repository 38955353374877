@mixin coloured-button($color: $primary, $text-color: $white) {
	color: $text-color;
	background: $color;

	&:hover {
		background: darken($color, 5%);
	}

	&:focus,
	&:active {
		box-shadow: 0 0 8px rgba($black, 0.085);
		background: darken($color, 6%);
	}
}

.btn {
	display: inline-block;
	vertical-align: top;
	padding: $btn-padding;
	font-size: $base-font-size;
	line-height: 22px;
	font-weight: bold;
	color: $btn-color;
	text-align: center;
	text-decoration: none;
	outline: none;
	border: 0;
	border-radius: 3px;
	background: $btn-background;

	&:hover {
		text-decoration: none;
		background: darken($btn-background, 5%);
	}

	&:focus,
	&:active {
		box-shadow: 0 0 8px rgba($black, 0.085);
		background: darken($btn-background, 6%);
	}

	&:disabled,
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.btn-primary {
		@include coloured-button();
	}

	&.btn-block {
		width: 100%;
	}

	&.btn-small {
		padding: 4px 10px;
	}

	&.btn-large {
		padding: 14px 20px;
	}

	&.btn-inverted {
		padding: 13px 22px;
		border: 2px solid currentColor;
		border-radius: 8px;
		background: none;

		&:hover {
			background: rgba($black, 0.15);
		}

		&:focus {
			box-shadow: 0 0 4px currentColor;
			background: rgba($black, 0.2);
		}
	}
}

.link-more {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding-right: 32px;
	font-size: 20px;
	line-height: 24px;
	font-weight: bold;

	@include media('<tablet') {
		font-size: 18px;
		line-height: 22px;
	}

	&:after {
		@include add-icon("\e900"); // icon-arrow-right
		position: absolute;
		right: 2px;
		top: 0.2em;
		font-size: 0.9em;
		transition: transform $animation-speed $animation-effect;
	}

	&:hover {
		text-decoration: none;

		&:after {
			transform: translateX(2px);
		}
	}
}