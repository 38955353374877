// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

// Palette
$black: #000;
$white:    #fff !default;
$gray-100: #F9F9F9 !default;
$gray-200: #EFF0F2 !default;
$gray-300: #E3E5EC !default;
$gray-400: #D1D4DC !default;
$gray-500: #BABEC8 !default;
$gray-600: #9599A4 !default;
$gray-700: #6A6D77 !default;
$gray-800: #474950 !default;
$gray-900: #27282C !default;
$black:    #000 !default;

$blue: #1C6CE4;
$blue-dark: #23272E;

$red: #db2023;
$green: #77bc47;
$turquoise: #11f3cb;
$pink: #d23e7b;
$yellow: #f9b42d;
$orange: #ee4111;
$purple: #a41ce4;

$primary: $blue;

$base-font-sans-serif: 'Manrope', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// Body
$body-color: $gray-800 !default;
$alt-text-color: $gray-900 !default;
$base-border-color: $gray-400 !default;
$alt-border-color: $gray-300 !default;
$alt-border-color-inverted: $gray-800 !default;
$base-background-color: $white !default;
$alt-background-color: $gray-200 !default;
$dark-background-color: $blue-dark !default;
$base-font-size: 18px !default;
$base-line-height: 30px !default; // 20px
$base-font-family: $base-font-sans-serif !default;
$base-min-width: 320px;
$icon-muted-color: $gray-700;
$text-muted-color: $gray-700;

// Liks
$base-link-color: $primary;
$base-link-hover-color: lighten($primary, 12%);

// Buttons
$btn-color: $body-color !default;
$btn-background: $alt-background-color !default;
$btn-padding: 9px 16px !default;

// Forms
$form-element-padding: $btn-padding !default;
$form-element-border-color: $base-border-color !default;
$form-element-background-color: $gray-100 !default;
$form-element-focus-border-color: $primary !default;
$form-element-text-color: $body-color !default;
$error-text-color: $red !default;
$placeholder-color: $gray-600 !default;
$placeholder-color-alt: $gray-500 !default;

// Headers
$h1-font-size: 56px !default;
$h2-font-size: 48px !default;
$h3-font-size: 32px !default;
$h4-font-size: 26px !default;
$h5-font-size: 23px !default;
$h6-font-size: 20px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;

$animation-speed: 0.2s;
$animation-effect: ease;

// Breakpoints
$breakpoints: (
	'xs-phone': 320px,
	'phone': 480px,
	'tablet': 768px,
	'desktop': 992px,
	'widescreen': 1200px
);

// Keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);